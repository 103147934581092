import React, { FC } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';
import { IArticleMedicalInfoComponentProps } from './model';

import './ArticleMedicalInfo.scss';

const ArticleMedicalInfo: FC<IArticleMedicalInfoComponentProps> = ({ title, body }) => {
  return (
    <div className="article-medical-info grey-default-bg">
      <Container fluid>
        <Row>
          <Col lg={{ span: 8, offset: 2 }}>
            {title ? <h2 className="article-medical-info__title">{title}</h2> : null}
            <DangerouslySetInnerHtml className="article-medical-info__body" html={body} />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ArticleMedicalInfo;
