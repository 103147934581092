import React, { FC } from 'react';
import classNames from 'classnames';

import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';

import { IHighlightedLinksProps } from './model';
import './HighlightedLinks.scss';

const HighlightedLinks: FC<IHighlightedLinksProps> = ({ title, points, lineColour = [] }) => (
  <div
    className={classNames('highlighted-links', {
      [`${lineColour[0]?.properties?.colorPicker?.label}-default-bg`]: lineColour,
    })}
  >
    <h3>{title}</h3>
    {points?.length ? (
      <ul>
        {points.map(({ properties: { text } }) => (
          <DangerouslySetInnerHtml key={text} html={text} element="li" />
        ))}
      </ul>
    ) : null}
  </div>
);
export default HighlightedLinks;
