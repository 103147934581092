import React, { FC, useEffect, useState } from 'react';
import sortBy from 'lodash.sortby';
import { graphql } from 'gatsby';
import classNames from 'classnames';
import Container from 'react-bootstrap/Container';

import ProductCard from 'components/ProductCard';
import ProductsCarousel from 'components/ProductsCarousel';
import { DEFAULT_PRODUCT_FAMILY_COLOR } from 'utils/constants';
import { gtmService } from 'services/gtmService';

import { RelatedProductsProps } from './model';
import responsiveObj from './constants';

import './RelatedProducts.scss';

const RelatedProducts: FC<RelatedProductsProps> = ({
  title,
  subTitle,
  btn,
  products,
  productsLinks,
  showBtn = false,
  carouselControls,
  listingName,
  displayAlternativeProductDescription,
  btnProduct,
  centerCards,
}) => {
  const [productsList, setProductsList] = useState<ProductCard.IProductCard[]>(products);

  useEffect(() => {
    let timeoutId;
    let productItems = products;

    if (productsLinks?.length) {
      const sortedProducts = sortBy(products, (item) => productsLinks?.indexOf(item.link));
      productItems = sortedProducts;
      setProductsList(sortedProducts);
    } else {
      setProductsList(products);
    }
    gtmService.emitProductListingView(listingName, productItems);

    return () => {
      timeoutId && clearTimeout(timeoutId);
    };
  }, [products, productsLinks]);

  return (
    <div
      className={classNames('related-products-wrapper', {
        [`related-products-wrapper--center-mode`]: centerCards,
      })}
    >
      <Container fluid>
        <ProductsCarousel
          title={title}
          subtitle={subTitle}
          responsiveObj={responsiveObj}
          carouselClass="related-product"
          btn={btn}
          {...carouselControls}
        >
          {productsList.map(
            ({
              sku,
              label,
              defaultProductImage,
              productLinks,
              productFamily,
              link,
              productImageAlt,
              productRelatedImage,
              productRelatedImageAlt,
              pageName,
              seoMetaDescription,
              preferred,
              productVariant,
              productDescription,
            }) => (
              <ProductCard
                key={sku}
                label={label}
                title={pageName}
                text={
                  displayAlternativeProductDescription && productDescription
                    ? productDescription
                    : seoMetaDescription
                }
                img={defaultProductImage}
                productRelatedImage={productRelatedImage}
                productRelatedImageAlt={productRelatedImageAlt}
                classes={classNames('nf-related-product-card', {
                  'with-related-image': productRelatedImage,
                })}
                btnProduct={btnProduct}
                bg={
                  productFamily?.[0]?.relatedProductBg?.[0]?.properties.colorPicker?.label ||
                  DEFAULT_PRODUCT_FAMILY_COLOR
                }
                btn={productLinks?.[0]?.properties}
                shopBtnShow={showBtn}
                link={link}
                alt={productImageAlt}
                pageName=""
                seoMetaDescription=""
                sku={sku}
                preferred={preferred}
                productVariant={productVariant}
              />
            )
          )}
        </ProductsCarousel>
      </Container>
    </div>
  );
};

export const query = graphql`
  fragment FragmentRelatedProductsConfig on IRelatedProductsConfig {
    relatedProductsTitle
    shopBtnsShow
    sectionCtaBtn {
      properties {
        ...FragmentNFButton
      }
    }
  }
`;

export default RelatedProducts;
