import React, { FC } from 'react';

import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import DangerouslySetInnerHTML from 'components/common/DangerouslySetInnerHtml';
import { ShopMessageProps } from './model';
import './ShopMessage.scss';

const ShopMessage: FC<ShopMessageProps> = ({
  blocks,
  title,
  linkText,
  linkUrl,
  shopMessageBgColor,
  shopMessageFontColor,
}) => (
  <div
    className="nf-shop-message"
    data-test="ShopMessage"
    style={
      shopMessageBgColor &&
      ({
        backgroundColor: `var(--${shopMessageBgColor})`,
      } as React.CSSProperties)
    }
  >
    <Container fluid>
      {title && (
        <h2
          className="nf-shop-message__title"
          style={
            shopMessageFontColor &&
            ({
              color: `var(--${shopMessageFontColor})`,
            } as React.CSSProperties)
          }
        >
          {title}
        </h2>
      )}
      <Row>
        {blocks?.length > 0
          ? blocks.map(({ properties: { icon, label } }, index) => (
              <Col lg={4} md={index === blocks.length - 1 ? 12 : 6} key={label}>
                <div className="nf-shop-message__block">
                  <DangerouslySetInnerHTML html={icon?.svg?.content} />
                  <span
                    className="text"
                    style={
                      shopMessageFontColor &&
                      ({
                        color: `var(--${shopMessageFontColor})`,
                      } as React.CSSProperties)
                    }
                  >
                    {label}
                  </span>
                </div>
              </Col>
            ))
          : null}
      </Row>
      {linkText && linkUrl && (
        <a
          aria-label={linkText}
          href={linkUrl}
          className="nf-shop-message__link"
          target="_blank"
          rel="noreferrer"
          style={
            shopMessageFontColor &&
            ({
              color: `var(--${shopMessageFontColor})`,
            } as React.CSSProperties)
          }
        >
          {linkText}
        </a>
      )}
    </Container>
  </div>
);
export default React.memo(ShopMessage);
