import { parseNFButton } from 'components/common/NFButton/parsers';

const parseCalloutSection = ({
  calloutBlock,
  tagsListBlock,
  featuredItemsSection,
}: AdvancedChildrenHubArticleTypes.ICalloutSection) => {
  const callout = {
    title: calloutBlock[0].properties.calloutTitle,
    description: calloutBlock[0].properties?.calloutText,
    color: calloutBlock[0].properties?.calloutColor?.[0]?.properties?.colorPicker?.label,
  };

  const tagsLists = tagsListBlock.map(({ properties: { tagsListTitle, tagsList, tagsFormat } }) => {
    const parsedTagsList = tagsList.map(({ properties: { tagItem, tagOverlayImage } }) => ({
      link: tagItem[0].relatedPage,
      name: tagItem[0].title,
      overlayImage: tagOverlayImage?.[0]?.properties,
    }));

    return {
      tagsListTitle,
      tagsList: parsedTagsList,
      tagsFormat,
    };
  });

  return {
    callout: { ...callout },
    tagsLists,
    featuredArticles: featuredItemsSection?.[0]?.properties?.featuredArticles,
    featuredArticlesCTAButton: featuredItemsSection?.[0]?.properties?.featuredItemsSectionCTA
      ?.length
      ? {
          ...parseNFButton(
            featuredItemsSection?.[0]?.properties?.featuredItemsSectionCTA[0].properties
          ),
        }
      : undefined,
  };
};

export default parseCalloutSection;
