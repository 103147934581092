import React, { FC } from 'react';
import { graphql } from 'gatsby';
import { Container, Row, Col } from 'react-bootstrap';

import ControlledCarousel from 'components/ControlledCarousel';
import ArticlePreview from 'components/ArticlePreview';
import TagLinksList from 'components/common/TagLinksList';
import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';
import NFButton from 'components/common/NFButton';

import { ICalloutComponentProps } from './model';

import './Callout.scss';

const articleCardsResponsiveObj = {
  desktop: {
    breakpoint: { max: 6000, min: 1060 },
    items: 2,
  },
  tablet: {
    breakpoint: { max: 1059, min: 0 },
    items: 1,
  },
};

const Callout: FC<ICalloutComponentProps> = ({
  title,
  description,
  calloutColor,
  tagsLists,
  featuredArticles,
  settings,
  featuredArticlesCTAButton,
}) => {
  return (
    <section className={`section-callout ${calloutColor}-default-bg`}>
      <Container fluid>
        <Row>
          <Col lg={{ span: 10, offset: 1 }}>
            <DangerouslySetInnerHtml html={title} className="section-callout-title" />

            {description ? (
              <DangerouslySetInnerHtml html={description} className="section-callout-description" />
            ) : null}

            {featuredArticles?.length === 1 ? (
              <ArticlePreview {...featuredArticles[0]} />
            ) : featuredArticles && featuredArticles?.length > 1 ? (
              <ControlledCarousel
                bgGradientColor="grey"
                responsiveObj={articleCardsResponsiveObj}
                carouselControls={settings?.carouselControls}
              >
                {featuredArticles.map((article) => (
                  <ArticlePreview key={article.title} {...article} />
                ))}
              </ControlledCarousel>
            ) : null}

            {featuredArticlesCTAButton ? (
              <div className="cta-wrapper">
                <NFButton {...featuredArticlesCTAButton} />
              </div>
            ) : null}

            {tagsLists.map(({ tagsListTitle, tagsList, tagsFormat }) => (
              <TagLinksList
                key={tagsFormat}
                title={tagsListTitle}
                tagsList={tagsList}
                tagsFormat={tagsFormat}
              />
            ))}
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export const query = graphql`
  fragment FragmentAdvancedChildrenHubCalloutSection on ICalloutSection {
    calloutBlock {
      properties {
        calloutText
        calloutTitle
        calloutColor {
          ...FragmentColorProps
        }
      }
    }
    tagsListBlock {
      properties {
        tagsListTitle
        tagsFormat
        tagsList {
          properties {
            tagItem {
              title
              relatedPage
            }
            tagOverlayImage {
              properties {
                imgAlt
                featuredImage {
                  fallbackUrl
                  gatsbyImage {
                    childImageSharp {
                      fluid(maxWidth: 400) {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    featuredItemsSection {
      properties {
        ...FragmentFeaturedItemsSection
      }
    }
  }
`;
export default Callout;
