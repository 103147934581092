import React, { FC } from 'react';
import { Link } from 'gatsby';

import IconCustom from 'components/common/IconCustom';

import { IArticleSelectorComponentProps } from './model';
import './ArticleSelector.scss';

const ArticleSelector: FC<IArticleSelectorComponentProps> = ({ col, prevTitle, nextTitle }) => (
  <div className="article-selector-holder" data-test="ArticleSelector">
    <div className="article-selector">
      {col.map((item, index) =>
        item ? (
          <Link key={item.title} to={item.link} className="article-selector__col">
            <div className="article-selector__arrow-block">
              <IconCustom icon="arrow-1" />
            </div>
            <div className="article-selector__article-name">
              <strong className="article-selector__nav-title">
                {index === 0 ? prevTitle : nextTitle}
              </strong>
              <strong className="article-selector__article-title">{item.title}</strong>
            </div>
          </Link>
        ) : null
      )}
    </div>
  </div>
);

export default ArticleSelector;
