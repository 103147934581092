import React, { FC } from 'react';
import { Link } from 'gatsby';
import classNames from 'classnames';

import UniversalImage from 'components/common/UniversalImage';
import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';

import { ITagLinksListComponentProps } from './model';

import './TagLinksList.scss';

const TagLinksList: FC<ITagLinksListComponentProps> = ({ title, tagsList, tagsFormat }) => {
  const listClassNames = classNames('list', {
    [`list--${tagsFormat}-tags`]: tagsFormat,
  });

  return (
    <section className="tags-links-list">
      {title ? <DangerouslySetInnerHtml html={title} className="title" /> : null}
      <ul className={listClassNames}>
        {tagsList.map(({ name, link, overlayImage }) => {
          if (tagsFormat === 'image' && !overlayImage) return null;

          return (
            <li key={name}>
              {link ? (
                <Link to={link} aria-label={name}>
                  {overlayImage ? (
                    <UniversalImage
                      img={overlayImage?.featuredImage}
                      imageAlt={overlayImage?.imgAlt}
                    />
                  ) : null}
                  {overlayImage ? <span>{name}</span> : name}
                </Link>
              ) : (
                <span>{name}</span>
              )}
            </li>
          );
        })}
      </ul>
    </section>
  );
};

export default TagLinksList;
