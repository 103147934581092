import React, { FC, useCallback, useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';

import ToDoList from 'components/ToDoList';
import CustomTabs from 'components/common/CustomTabs';
import WhatToDoTable from 'components/WhatToDoTable';

import { IPropsWhatToDoInfo } from './model';
import './WhatToDoInfo.scss';

const WhatToDoInfo: FC<IPropsWhatToDoInfo> = ({ items, isMobile }) => {
  const [tabWhatToDoIndex, setTabWhatToDoIndex] = useState(0);

  useEffect(() => {
    const selectedIndex = items.findIndex(({ properties }) => properties.isOpenedOnMobile === '1');
    if (selectedIndex < 0) {
      return;
    }
    setTabWhatToDoIndex(selectedIndex);
  }, []);

  const onSelectCallback = useCallback((index: number) => {
    setTabWhatToDoIndex(index);
  }, []);

  return items?.length > 0 ? (
    <div data-test="WhatToDoInfo" className="what-to-do-info-wrapper">
      <Container fluid>
        {isMobile ? (
          <CustomTabs
            items={items.map(({ properties }) => ({
              tabTitle: {
                name: properties.title,
                ariaLabel: properties.title,
              },
              content: <ToDoList list={properties.listItem} redBullet={properties.redBullet} />,
            }))}
            selectedIndex={tabWhatToDoIndex}
            onSelectCallback={onSelectCallback}
          />
        ) : (
          <WhatToDoTable col={items} />
        )}
      </Container>
    </div>
  ) : null;
};

export default WhatToDoInfo;
