import React, { createRef, FC, RefObject, useCallback, useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import classNames from 'classnames';
import Accordion from 'react-tiny-accordion';

import { isBrowser } from 'utils/browser';
import useScreenRecognition from 'hooks/useScreenRecognition';
import NFAccordionHeader from 'components/common/NFAccordion/NFAccordionHeader';
import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';
import ELEMENTS from 'utils/constants';

import { INFAccordionItem, IPropsNFAccordion } from './model';
import './NFAccordion.scss';

const NFAccordion: FC<IPropsNFAccordion> = (props) => {
  const {
    items,
    selectedIndex,
    onChange,
    changeOnClick,
    headerClassName,
    wrapperHeaderClassNames,
    scrollTabIntoView,
  } = props;
  const { isMobile } = useScreenRecognition();
  const [tabsHeaderRef, setTabsHeaderRef] = useState<RefObject<HTMLDivElement>[] | []>([]);
  const [tabsContentRef, setTabsContentRef] = useState<RefObject<HTMLDivElement>[] | []>([]);
  const [panels, setPanels] = useState<INFAccordionItem[]>();
  const [fontsLoaded, setFontsLoaded] = useState<boolean>(false);

  const header: HTMLElement | null = isBrowser()
    ? document.getElementById(ELEMENTS.HEADER_ID)
    : null;
  const headerHeight: number = header ? header.clientHeight : 0;

  const onChangeCallback = useCallback(
    (index: number, expanded: boolean, selectedCallbackIndex: number) => {
      if (onChange) {
        onChange({ index, expanded, selectedIndex: selectedCallbackIndex });
      }

      if (scrollTabIntoView && panels?.[selectedCallbackIndex]?.offset) {
        window.scrollTo({
          top: panels[selectedCallbackIndex].offset,
          behavior: isMobile ? 'auto' : 'smooth',
        });
      }
    },
    [tabsHeaderRef, panels]
  );

  const areFontsReady = () => {
    (document as any).fonts?.ready
      .then(() => setFontsLoaded(true))
      .catch(() => {
        setFontsLoaded(false);
        console.error('Fonts were not loaded');
      });
  };

  useEffect(() => {
    areFontsReady();
    const headersRefs: RefObject<HTMLDivElement>[] = items.map(() => createRef());
    setTabsHeaderRef(headersRefs);
    const contentRefs: RefObject<HTMLDivElement>[] = items.map(() => createRef());
    setTabsContentRef(contentRefs);
  }, []);

  useEffect(() => {
    if (scrollTabIntoView) {
      const accordionPanels = items.map((panel, i) => {
        const topOffset = tabsHeaderRef?.[i]?.current?.getBoundingClientRect().top || 0;
        const openedTabHeight =
          panel.isOpened === '1'
            ? 0
            : tabsContentRef.filter((tabContent) =>
                tabContent.current?.classList.contains('is-open')
              )?.[0]?.current?.clientHeight || 0;

        const offset = topOffset + window.pageYOffset - openedTabHeight - headerHeight;

        return {
          ...panel,
          offset,
        };
      });
      setPanels(accordionPanels);
    }
  }, [tabsHeaderRef, tabsContentRef, fontsLoaded]);

  useEffect(() => {
    !scrollTabIntoView && setPanels(items);
  }, [items]);

  return panels ? (
    <div className="nf-accordion">
      <Accordion
        className="accordion"
        openClassName="open"
        selectedIndex={selectedIndex}
        transitionDuration={scrollTabIntoView ? (isMobile ? 0 : 200) : 200}
        onChange={onChangeCallback}
        changeOnClick={changeOnClick}
        data-test="Accordion"
      >
        {panels.map((item: INFAccordionItem, i) =>
          item.content && item.header ? (
            <div
              key={item.header}
              className="nf-accordion--header-wrapper"
              data-header={
                <NFAccordionHeader
                  header={item.header}
                  className={classNames({ [headerClassName || '']: headerClassName })}
                  wrapperClassNames={wrapperHeaderClassNames}
                  headerRef={tabsHeaderRef[i]}
                />
              }
            >
              <div
                ref={tabsContentRef[i]}
                className={`nf-accordion--content ${item.isOpened === '1' ? 'is-open' : ''}`}
              >
                {typeof item.content === 'string' ? (
                  <DangerouslySetInnerHtml html={item.content} />
                ) : (
                  item.content
                )}
              </div>
            </div>
          ) : null
        )}
      </Accordion>
    </div>
  ) : null;
};

export const query = graphql`
  fragment FragmentAccordion on IAccordion {
    accordion {
      header
      content
      isOpened
      name
      descriptionProducts
    }
  }

  fragment FragmentAccordionData on IAccordionData {
    accordionData {
      name
      isOpened
      descriptionProduct
    }
  }

  fragment FragmentAccordionCommon on IAccordionCommon {
    items {
      properties {
        header
        content
        isOpened
      }
    }
  }

  fragment FragmentAccordionCommonWithProps on IAccordionCommonWithProps {
    properties {
      items {
        properties {
          header
          content
          isOpened
        }
      }
    }
  }
`;

export default NFAccordion;
