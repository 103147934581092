import React, { FC } from 'react';
import classNames from 'classnames';
import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';

import { IToDoListComponentProps } from './model';

import 'components/ToDoList/ToDoList.scss';

const ToDoList: FC<IToDoListComponentProps> = ({ list, redBullet }) => {
  const classes = classNames('to-do-list', {
    'to-do-list--red-bullet': redBullet === '1',
  });

  return (
    <ul className={classes}>
      {list.map(({ properties: { name } }) => (
        <DangerouslySetInnerHtml key={name} html={name} element="li" />
      ))}
    </ul>
  );
};

export default ToDoList;
