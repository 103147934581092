import React, { FC } from 'react';
import { graphql } from 'gatsby';

import useScreenRecognition from 'hooks/useScreenRecognition';

import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';
import UniversalImage from 'components/common/UniversalImage';
import NumberDotList from 'components/common/NumberDotList';

import { HighlightedListProps } from './model';
import './HighlightedList.scss';

const HighlightedList: FC<HighlightedListProps> = ({
  title,
  list,
  img,
  imgPosition = 'top',
  imgAltText = '',
  pointsColor,
}) => {
  const { isMobile } = useScreenRecognition();

  const Img = ({ type }) =>
    img?.fallbackUrl ? (
      <UniversalImage
        imageAlt={imgAltText}
        img={img}
        wrapperClassName={`nf-highlighted-list__img img-for-${type}`}
      />
    ) : null;

  return (
    <div className="nf-highlighted-list ">
      <div className={`nf-highlighted-list__content img-mode--${imgPosition}`}>
        <div className="d-flex flex-column">
          {title ? (
            <h2
              className="nf-highlighted-list__title"
              dangerouslySetInnerHTML={{ __html: title }}
            />
          ) : null}
          {isMobile ? <Img type="mobile" /> : null}
          {list?.length > 0 ? (
            <ul className="nf-highlighted-list__list">
              {list.map(({ properties: { text } }, index) => {
                const id = `${text}_${index}`;

                return (
                  <li key={id}>
                    <NumberDotList bgColor={pointsColor} number={index + 1} />
                    <DangerouslySetInnerHtml html={text} />
                  </li>
                );
              })}
            </ul>
          ) : null}
        </div>
        {!isMobile ? <Img type="desktop" /> : null}
      </div>
    </div>
  );
};
export default HighlightedList;

export const query = graphql`
  fragment FragmentHighlightedList on IArticleBodySection {
    limit
    highlightedList {
      properties {
        text
      }
    }
    position
    imgAlt
    pointsColor {
      properties {
        colorPicker {
          label
        }
      }
    }
    image {
      fallbackUrl
      gatsbyImage {
        childImageSharp {
          fluid(maxWidth: 1400) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  }
`;
