import React, { FC, ReactElement } from 'react';
import classNames from 'classnames';

import IconCustom from 'components/common/IconCustom';
import NFAccordionHeaderTitle from './NFAccordionHeaderTitle';

import './NFAccordionHeader.scss';
import { IPropsNFAccordionHeader } from './model';

const NFAccordionHeader: FC<IPropsNFAccordionHeader> = (props): ReactElement => {
  const { header, className, wrapperClassNames, headerRef } = props;

  const wrapperClassName = classNames('accordion-header-holder', {
    [wrapperClassNames || '']: wrapperClassNames,
  });

  return (
    <div className={wrapperClassName} ref={headerRef}>
      <button type="button" className="nf-accordion-header">
        <NFAccordionHeaderTitle classes={`nf-accordion-header--text ${className}`} title={header} />
        <IconCustom icon="arrow-1" />
      </button>
    </div>
  );
};

export default NFAccordionHeader;
