import React, { FC, useCallback, useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';

import NFAccordion from 'components/common/NFAccordion';
import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';

import { IPropsSymptomsFaq } from './model';
import './SymptomsFaq.scss';

const SymptomsFaq: FC<IPropsSymptomsFaq> = ({ symptomsFAQ, wrapperHeaderClassNames }) => {
  const [selectedAccordionIndex, setSelectedAccordionIndex] = useState<number>(-1);

  useEffect(() => {
    if (symptomsFAQ?.length) {
      return;
    }
    const selectedIndex = symptomsFAQ.findIndex(({ properties }) => properties.isOpen === '1');
    if (selectedIndex < 0) {
      return;
    }
    setSelectedAccordionIndex(selectedIndex);
  }, []);

  const onChangeCallback = useCallback(({ selectedIndex }) => {
    setSelectedAccordionIndex(selectedIndex);
  }, []);

  return symptomsFAQ?.length > 0 ? (
    <div data-test="SymptomsFaq" className="symptoms-faq-wrapper">
      <Container fluid>
        <NFAccordion
          onChange={onChangeCallback}
          changeOnClick={false}
          items={symptomsFAQ.map(({ properties: { title: headerTitle, content } }) => ({
            header: headerTitle,
            content: <DangerouslySetInnerHtml html={content} />,
          }))}
          selectedIndex={selectedAccordionIndex}
          wrapperHeaderClassNames={wrapperHeaderClassNames}
          scrollTabIntoView
        />
      </Container>
    </div>
  ) : null;
};

export default SymptomsFaq;
