import React, { FC } from 'react';

import ToDoList from 'components/ToDoList';

import { IWhatToDoTableComponentProps } from './model';

import 'components/WhatToDoTable/WhatToDoTable.scss';

const WhatToDoTable: FC<IWhatToDoTableComponentProps> = ({ col }) => {
  return (
    <div className="what-to-do-table">
      {col.map(({ properties: { title, listItem, redBullet } }, index) => {
        const id = `${title}_${index}`;

        return (
          <div key={id} className="what-to-do-table__col">
            <strong className="title">{title}</strong>
            <ToDoList list={listItem} redBullet={redBullet} />
          </div>
        );
      })}
    </div>
  );
};

export default WhatToDoTable;
