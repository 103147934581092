import React, { FC } from 'react';
import { graphql } from 'gatsby';
import GatsbyImage from '../common/GatsbyImage';
import { ExpertReviewProps } from './model';

const ExpertReview: FC<ExpertReviewProps> = ({
  expertImage,
  expertName,
  expertProfession,
  expertReview,
  imageAlt,
}) => {
  return (
    <div className="expert-widget">
      <div className="expert-widget__image">
        <GatsbyImage fluid={expertImage!.gatsbyImage!.childImageSharp!.fluid} alt={imageAlt} />
      </div>
      <div className="expert-widget__name">
        <h3>{expertName}</h3>
      </div>
      <div className="expert-widget__profession">
        <p>{expertProfession}</p>
      </div>
      <div className="expert-widget__content">
        <p>{expertReview}</p>
      </div>
    </div>
  );
};

export const query = graphql`
  fragment FragmentExpertOpinion on TExpert {
    expertName
    expertProfession
    expertReview

    expertImage {
      fallbackUrl
      gatsbyImage {
        childImageSharp {
          fluid(maxWidth: 150, maxHeight: 150, cropFocus: ENTROPY) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  }
`;

export default ExpertReview;
