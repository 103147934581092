import React, { FC, useEffect, useState } from 'react';
import classNames from 'classnames';
import ExtraGap from 'components/common/ExtraGap';
import ControlledCarousel from 'components/ControlledCarousel';
import GatsbyImage from '../common/GatsbyImage';
import ExpertReview from '../ExpertReview/ExpertReview';
import { VideosCarouselProps } from './model';
import './VideosCarousel.scss';

const VideosCarousel: FC<VideosCarouselProps> = ({
  title,
  subtitle,
  carouselClass,
  children,
  responsiveObj,
  bgColor = 'white',
  ariaLabelPrev,
  ariaLabelNext,
  videos,
}) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const images = videos.map(
    ({
      properties: {
        video: [
          {
            properties: { previewImg, videoTitle },
          },
        ],
      },
    }) => {
      return previewImg && previewImg[0].properties.featuredImage?.gatsbyImage?.childImageSharp ? (
        <>
          <GatsbyImage
            fluid={previewImg[0].properties.featuredImage.gatsbyImage.childImageSharp.fluid}
            alt={previewImg[0].properties.imgAlt}
          />
          <p>{videoTitle}</p>
        </>
      ) : null;
    }
  );

  const expertWidget = videos.map(
    ({
      properties: {
        expertOpinion: [
          {
            properties: { expertImage, expertName, expertProfession, expertReview, imageAlt },
          },
        ],
      },
    }) => (
      <ExpertReview {...{ expertImage, expertName, expertProfession, expertReview, imageAlt }} />
    )
  );

  const CustomDot = ({ index, onClick, active, carouselState: { currentSlide } }) => {
    useEffect(() => {
      setActiveIndex(currentSlide);
    }, [currentSlide]);

    return (
      <li>
        <button
          type="button"
          onClick={(e) => {
            onClick();
            e.preventDefault();
          }}
          className={classNames('nf-videos-carousel__dots', {
            'nf-videos-carousel__dots--active': active,
          })}
        >
          {images[index]}
        </button>
      </li>
    );
  };

  return (
    <div className="nf-videos-carousel">
      {title ? <h2 className="nf-videos-carousel__title">{title}</h2> : null}
      {subtitle ? <p className="nf-videos-carousel__subtitle">{subtitle}</p> : null}
      {title && !subtitle ? <ExtraGap /> : null}
      <ControlledCarousel
        showDots
        // @ts-ignore
        customDot={<CustomDot />}
        bgGradientColor={bgColor}
        classes={`${carouselClass}-carousel`}
        responsiveObj={responsiveObj}
        carouselControls={{
          ariaLabelPrev,
          ariaLabelNext,
        }}
      >
        {children}
      </ControlledCarousel>
      {expertWidget[activeIndex]}
    </div>
  );
};
export default VideosCarousel;
