import React, { FC } from 'react';
import Container from 'react-bootstrap/Container';
import VideosCarousel from '../VideosCarousel';
import Video from '../common/Video';
import { ExpertVideosListProps } from './model';
import responsiveObj from './constants';
import './ExpertVideosList.scss';

const ExpertVideosList: FC<ExpertVideosListProps> = ({
  title,
  subtitle,
  carouselControls,
  videoListWithExperts,
}) => {
  return (
    <div className="expert-videos-wrapper">
      <Container fluid>
        <VideosCarousel
          title={title}
          subtitle={subtitle}
          responsiveObj={responsiveObj}
          carouselClass="videos-list"
          videos={videoListWithExperts}
          {...carouselControls}
        >
          {videoListWithExperts.map(
            ({
              properties: {
                video: [
                  {
                    properties: {
                      btnPlay,
                      previewImg,
                      videoLink,
                      videoTitle,
                      isVideoRounded,
                      btnPlayAriaLabel,
                    },
                  },
                ],
              },
            }) => (
              <Video
                videoTitle={videoTitle}
                btnPlay={btnPlay?.svg?.content}
                btnPlayAriaLabel={btnPlayAriaLabel}
                previewImg={previewImg[0].properties}
                video={videoLink}
                isVideoRounded={isVideoRounded}
              />
            )
          )}
        </VideosCarousel>
      </Container>
    </div>
  );
};
export default ExpertVideosList;
