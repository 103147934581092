import React, { FC } from 'react';
import { Container } from 'react-bootstrap';

import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';

import { IPropsColumnList } from './model';
import './ColumnList.scss';

const ColumnList: FC<IPropsColumnList> = ({ columnList }) => {
  return columnList?.length ? (
    <Container fluid>
      <div data-test="ColumnList" className="column-list-wrapper">
        {columnList.map(({ properties: { list } }) => (
          <div key={list} className="col">
            <DangerouslySetInnerHtml html={list} />
          </div>
        ))}
      </div>
    </Container>
  ) : null;
};

export default ColumnList;
